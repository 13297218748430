<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Cases List</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/cases/create'])"
                         :to="'/cases/create/'+lang"
                         class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <div>
          <general-data-table
            :key="componentKey"
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :method="'POST'"
            :row-key="'id'"
            :api-url="'cases/index'"
            :lang="lang"
          >
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/cases/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/cases/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/cases/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/cases/update', scope.row.creator_id)"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/cases/edit/'+scope.row.id+'/'+lang">
                  <i class="fa fa-edit"></i></router-link>

                <router-link  v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/cases_subscribers/index'])"
                              v-tooltip.top-center="'subscribers'"
                              class="btn-info btn-simple btn-link"
                              :to="'/cases/subscribers/' + scope.row.id + '/' + lang"><i class="fa fa-envelope"></i></router-link>

                <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['cases_' + lang+'_logs_view'])"
                              v-tooltip.top-center="'Log'"
                              class="btn-info btn-simple btn-link"
                              :to="'/logs/cases_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/cases/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/cases/delete', scope.row.creator_id)"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Case?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {
    return {
      tableColumns: [],
      fixedColumn: null,
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      componentKey:0,
      advanceSearchFilter:[],
      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: null,
      categoryList:[],
      authorList:[],
      sourceList:[],
      tagList:[],
    }

  },
  watch:{
    $route (to, from){
      this.componentKey++;
      this.initialComponent();
    }
  },
  created(){
    this.initialComponent();
  },
  methods: {
    initialComponent(){
      this.responsiveViewPort();
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1)
      let data = {
        'lang': this.lang,
      };
      this.axios.post("cases/builder", data).then((response) => {
        this.tagList = response.data.tags;
        this.setAdvanceSearch();
      }).catch((error) => {
        console.error(error);
      });
    },
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
        lang: this.lang,
      }
      this.axios.post("cases/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "case updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {

      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
        'id': this.toDeleteId,
        lang:this.lang,
      }
      this.axios.delete("cases/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "case deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    setAdvanceSearch(){
      this.advanceSearchFilter = [
        {
          key: 'cases.title',
          type: 'text',
          frontType:'simple',
          label: 'Title',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'cases.url',
          type: 'text',
          frontType:'simple',
          label: 'Url',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'cases.is_active',
          type: 'text',
          frontType:'switch',
          label: 'Is Active',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'cases.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'Created after',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'cases.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'Created before',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'cases.date',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'after Date',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'cases.date',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: ' before date',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
      ];
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
            this.tableColumns = [
                {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
                {label: 'URL', value: 'url', minWidth: '200', align: 'center'},
            ];
        }else{
            this.fixedColumn = 'right';
            this.tableColumns = [
                {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
                {label: 'Sub Title', value: 'sub_title', minWidth: '200', align: 'center'},
                {label: 'URL', value: 'url', minWidth: '200', align: 'center'},
            ];
        }
    }
  },

}
</script>
