<template>

  <div class="row" ref="caseForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="title *"
                            name="title"
                            fou
                            @keyup="generateUrlAndMetaTitleFields"
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="title_color"
                  rules="required"
                  name="The Title Color"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="title_color"
                    size="large"
                    filterable
                    clearable
                    placeholder="Title Color *"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-info'"
                    :label="'Title Color *'"
                    :list="titleColorList"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="formData.title_color">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="sub_title"
                  name="The Sub Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="sub title"
                            name="sub_title"
                            fou

                            v-model="formData.sub_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="excerpt"
                  rules=""
                  name="The Excerpt"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           label="excerpt"
                           name="excerpt"
                           @keyup="generateMetaDesc"
                           fou
                           v-model="formData.excerpt">
                  </fg-text>
                </ValidationProvider>
                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right" >
                    <div slot="content">800 * 600</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <single-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.image"
                    :accepted-files="imagesExtensions">
                  </single-file-uploader-file-manager>
                </div>
                <div class="form-group">
                  <label>Cover Image</label>
                  <el-tooltip placement="right" >
                    <div slot="content">1800 * 900</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <single-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.cover_image"
                    :accepted-files="imagesExtensions">
                  </single-file-uploader-file-manager>
                </div>
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init="editorConfig"
                    :key="editorKeyComponent"
                  />
                </div>
                <ValidationProvider
                  vid="video_type"
                  rules=""
                  name="The Video Type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="video_type"
                    size="large"
                    filterable
                    clearable
                    placeholder="Video Type"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Video Type'"
                    :list="VideoTypes"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="formData.video_type">
                  </fg-select>
                </ValidationProvider>
                <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
                  <label>Uploaded Video</label>
                  <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                    <div slot="content">{{ getBannerVideoInfo() }}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <single-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.uploaded_video"
                    :accepted-files="videoExtensions"
                    :file-type="'video'">
                  </single-file-uploader-file-manager>
                </div>
                <div class="form-group" v-if="formData.video_type === 'EMBEDDED'">
                  <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                    <div slot="content">{{ getBannerVideoInfo() }}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <ValidationProvider
                    vid="embedded_video"
                    rules=""
                    name="The Embedded Video"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Embedded Video"
                              name="embedded_video"
                              fou
                              v-model="formData.embedded_video">
                    </fg-input>
                  </ValidationProvider>
                  <div v-html="formData.embedded_video">
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="col-md-12 col-sm-12 extra-fields">
                  <h4>Publish Status</h4>
                  <div class="col-md-12 col-sm-12">
                    <label class="card-label">Date</label>
                    <fg-input v-model="formData.date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose News publish date"
                    >
                    </fg-input>
                    <div class="form-group pt-4">
                      <label>Is Active</label>&nbsp;
                      <l-switch v-model="formData.is_active">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 extra-fields">
                  <h4>Tags</h4>
                  <div class="col-md-12 col-sm-12">
                    <div class="d-flex justify-content-start">
                      <ValidationProvider
                        vid="tag_id"
                        rules=""
                        name="The Tag"
                        v-slot="{ passed, failed,errors }">
                        <fg-select
                          name="tag_id"
                          size="large"
                          multiple
                          filterable
                          clearable
                          placeholder="Tag"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-info'"
                          :label="'Tags'"
                          :list="tagList"
                          :listItemLabel="'name'"
                          :listItemValue="'id'"
                          v-model="formData.tags">
                        </fg-select>
                      </ValidationProvider>
                      <div class="d-flex align-items-center ml-4">
                        <i v-if="$store.getters['auth/haveOneOfPermissions']([formData.lang+'/tags/create'])"
                           @click="openAddingModal('tags/create','New Tag')"
                           type="button" class="fas fa-plus-circle align-middle"
                           v-tooltip.top-center="'Add new Tag'"></i>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-12 col-sm-12 extra-fields">
                  <h4>SEO Settings</h4>

                  <div class="col-md-12 col-sm-12">
                    <ValidationProvider
                      vid="url"
                      rules="required"
                      name="The URL"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="url *"
                                name="url"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="meta_title"
                      rules=""
                      name="The Meta Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Meta Title"
                                name="meta_title"
                                fou
                                v-model="formData.meta_title">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="meta_description"
                      rules=""
                      name="The Meta Description"
                      v-slot="{ passed, failed,errors }">
                      <fg-text type="text"
                               :error="failed ? errors[0]: null"
                               label="Meta Description"
                               name="meta_description"
                               fou
                               v-model="formData.meta_description">
                      </fg-text>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="meta_keywords"
                      rules=""
                      name="The Meta Keywords"
                      v-slot="{ passed, failed,errors }">
                      <fg-text type="text"
                               :error="failed ? errors[0]: null"
                               label="Meta Keywords"
                               name="meta_keywords"
                               fou
                               v-model="formData.meta_keywords">
                      </fg-text>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Gallery</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{ getBannerImageInfo() }}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <multiple-file-uploader-file-manager
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :filesNumber="20"
                    :accepted-files="imagesExtensions"
                    v-model="formData.gallery"
                  >
                  </multiple-file-uploader-file-manager>
                </div>
              </div>

              <div class="col-12">
                <paginated-select
                  :labelTitle="'Choose News'"
                  :isMultiple="true"
                  @select-changed="setCaseNews"
                  :apiUrl="formData.lang +'/news/paginatedNews'"
                  :initData="formData.backCaseNews"
                  :key="paginatedCompKey"
                >
                </paginated-select>

                <draggable  class="container" v-model="formData.backCaseNews" group="news" @start="drag=true">
                  <div v-for="(item, x) in formData.backCaseNews" :key="'Item_'+x">
                    <card class="mt-2 mb-0 text-right">
                      <span>{{ item.title }}</span>
                      <span class="badge badge-success mx-2">{{ x + 1 }}</span>
                    </card>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/cases/list/'+formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>
    <quick-adding-modal
      :visible="addingModalVisibility"
      :url="addingTypeUrl"
      :title="addingModalTitle"
      @close="closeAddingModal()"
    >
    </quick-adding-modal>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import GeneralDataTable from "@/components/GeneralDataTable";
import QuickAddingModal from "@/pages/news/news/QuickAddingModal"
import PaginatedSelect from "@/components/paginatedSelect";
import draggable from 'vuedraggable';
import SingleFileUploaderFileManager from "@/components/FileManager/SingleFileUploaderFileManager";
import MultipleFileUploaderFileManager from "@/components/FileManager/MultipleFileUploaderFileManager";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    MultipleFileUploaderFileManager,
    SingleFileUploaderFileManager,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
    GeneralDataTable,
    'editor': Editor,
    QuickAddingModal,
    PaginatedSelect,
    draggable
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      paginatedCompKey: 0,
      editorConfig: this.getEditorConfig(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      imagesExtensions:this.getImagesExtensions(),
      videoExtensions:this.getVideoExtensions(),
      addingModalVisibility: false,
      addingTypeUrl: '',
      addingModalTitle: '',
      formData: {
        lang: "",
        tags: [],
        title: "",
        sub_title: "",
        excerpt: "",
        url: "",
        content: "",
        image: "",
        cover_image:"",
        file: "",
        date: "",
        is_active: true,
        gallery: [],
        backCaseNews: [],
        video_type: '',
        uploaded_video: "",
        embedded_video: "",
        meta_title: "",
        meta_keywords: "",
        meta_description: "",
        title_color: '',
      },
      tagList: [],
      VideoTypes: [],
      titleColorList: [],
      editorKeyComponent: 0,
    };
  },

  created() {
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.caseForm
    });
    this.getBuilderData();
    if (this.formData.lang === 'ar'){
      this.editorConfig.content_style += ' body{direction: rtl;text-align:right;}';
      this.editorKeyComponent++;
    }

  },


  methods: {
    getBuilderData() {
      let data = {lang: this.formData.lang};
      this.axios.post("cases/builder", data).then((response) => {
        this.tagList = response.data.tags;
        this.VideoTypes = response.data.videoTypes;
        this.titleColorList = response.data.titleColorTypes;
        this.formData.title_color = this.titleColorList[0].value;
        this.id = this.$route.params['id'];
        if (this.id !== undefined) {
          this.editMode = true;
          this.formTitle = "Edit case";
          this.getCase();
        } else {
          this.editMode = false;
          this.formTitle = "Add case";
          this.formData.date = this.getDateNow();
          this.loader.hide();
        }

      }).catch((error) => {
        console.error(error);
      });
    },
    getCase() {
      let data = {
        id: this.id,
        lang: this.formData.lang
      }
      this.axios.post("cases/get", data).then((response) => {
        this.formData = response.data;
        this.formData.tags = response.data.tags.map(tag => tag.id);
        this.formData.gallery = response.data.gallery;
        this.formData.date = this.vueDate(this.formData.date);
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "case Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let formData = {...this.formData};

      formData.backCaseNews = formData.backCaseNews.map(
        (element, index) => {
          return {"news_id": element.id, "sort_order": index + 1}
        }
      );
      if (formData.video_type === "UPLOADED") {
        formData.embedded_video = '';
      } else if (this.formData.video_type === "EMBEDDED") {
        formData.uploaded_video = '';
      } else {
        formData.embedded_video = '';
        formData.uploaded_video = '';
      }
      formData.date = this.laravelDate(formData.date);
      if (this.editMode === true) {
        request = this.axios.put("cases/update/" + this.id, formData);
        successMessage = "case Updated Successfully";
      } else {
        request = this.axios.post("cases/create", formData);
        successMessage = "case Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/cases/list/" + this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if (this.mediaInfo && this.mediaInfo["cases_image"]) {
        return this.mediaInfo["cases_image"];
      } else {
        return "";
      }
    },
    generateUrlAndMetaTitleFields() {

      let newUrl = '';
      if (this.formData.title) {
        this.formData.meta_title = this.formData.title;
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    },
    generateMetaDesc() {
      this.formData.meta_description = this.formData.excerpt;
    },
    getBannerVideoInfo() {
      if (this.mediaInfo && this.mediaInfo["cases_video"]) {
        return this.mediaInfo["cases_video"];
      } else {
        return "";
      }
    },
    getDateNow() {
      const d = new Date;
      return [
          d.getFullYear(),
          ("0" + (d.getMonth() + 1)).slice(-2),
          ("0" + d.getDate()).slice(-2)
        ]
          .join('-') + 'T' +
        [
          ("0" + d.getHours()).slice(-2),
          ("0" + d.getMinutes()).slice(-2),
          ("0" + d.getSeconds()).slice(-2)
        ]
          .join(':');
    },
    vueDate(date) {
      return (date) ? date.replace(' ', 'T') : date;
    },
    laravelDate(date) {
      return (date) ? date.replace('T', ' ') : date;
    },
    openAddingModal(addingTypeUrl, title) {
      this.addingTypeUrl = addingTypeUrl;
      this.addingModalTitle = title;
      this.addingModalVisibility = true;
    },
    closeAddingModal() {
      this.getBuilderData();
      this.addingModalVisibility = false;
    },
    setCaseNews(selectedNews) {
      this.formData.backCaseNews = selectedNews
    },
  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0 0 3px;
}

.extra-fields > h4 {
  padding: 20px;
}
</style>
